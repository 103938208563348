import React, { useEffect, useRef, useState } from "react";
import styles from "./CompareRecommendedDeals.module.scss";
import { ReactComponent as DropDownArrow } from "../../../assets/logos/dropDownArrow.svg";
import { ReactComponent as TickBadgeIcon } from "../../../assets/logos/tick-badge-icon.svg";
import { ReactComponent as CrossIcon } from "../../../assets/logos/cross-icon.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { numberWithCommas } from "../YourAdvance/helper";
import { get, invoke } from "lodash";
import { DEFAULT_PARTNER } from "../YourAdvance/constants";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  API_URL,
  ARTIST_API,
  COMPARE_RECOMMENDED_OFFER,
  USER_API,
} from "../constants";
import request from "../../../utils/request";
import { toast } from "react-toastify";
import Loader from "../../../component/Loader";
import { GetErrorMessage } from "../helper";
import { COMPARE_DEAL_STEPS, DEAL_LIST } from "./constants";
import segment from "../../../utils/segment";
import { useMediaQuery } from "../../../utils/Helper";
import CompareDealChart from "./CompareDealChart";

const CompareRecommendedDeals = (props) => {
  const [comparisionDetailList, setComparisionDetailList] = useState([]);
  const [currentStep, setCurrentStep] = useState(COMPARE_DEAL_STEPS[0]);
  const [loading, setLoading] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const isMobile = useMediaQuery("(max-width:767px)");
  const [swipeDivHeight, setSwipeDivHeight] = useState(0);
  const [chartDivHeight, setChartDivHeight] = useState(0);
  const swipeDivRef = useRef(null);

  const saveRecommenedCardDeal = (offer) => {
    invoke(segment, "track.selectedFinalOffer");
    invoke(props, "saveRecommenedDeal", offer, false);
    return true;
  };

  const getCompareDealsData = (props) => {
    setLoading(true);
    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${COMPARE_RECOMMENDED_OFFER}`;
    const payload = {
      method: "POST",
      body: props.dealList,
    };
    request(requestURL, payload)
      .then((res) => {
        setLoading(false);
        if (get(res, "status")) {
          setComparisionDetailList(get(res, "data", []));
          return true;
        }
        toast.error(get(res, "message"));
        invoke(props, "setParentState", {
          compareOffers: false,
        });
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        invoke(props, "setParentState", {
          compareOffers: false,
        });
      });
  };

  const renderDisabledItem = (distributor) => {
    return distributor === DEFAULT_PARTNER ? (
      <span className={styles.noService}>N/A</span>
    ) : (
      <CrossIcon />
    );
  };

  useEffect(() => {
    if (props?.dealList) {
      getCompareDealsData(props);
    }
  }, [props.dealList]);

  useEffect(() => {
    if (swipeDivRef.current) {
      setSwipeDivHeight(swipeDivRef.current.clientHeight);
    }
  }, [isMobile, comparisionDetailList]);

  const renderDesktopCompareTable = () => {
    return (
      <div className={styles.tableMainDiv}>
        <TableContainer className={styles.tableContainerDesktop}>
          <Table
            className={styles.table}
            style={{ width: "100%" }}
            stickyHeader
            aria-label="distributor table"
          >
            <TableBody>
              <TableRow className={styles.headerRow}>
                <TableCell
                  component="th"
                  scope="row"
                  className={`${styles.sticky} ${styles.title}`}
                >
                  {""}
                </TableCell>
                {comparisionDetailList.map((el, index) => (
                  <TableCell key={`cell-${index}`} className={styles.value}>
                    <div className={styles.partnerCell}>{DEAL_LIST[index]}</div>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell className={styles.sticky} component="th" scope="row">
                  Total Advance
                </TableCell>
                {comparisionDetailList.map((el, index) => (
                  <TableCell
                    key={`cell-${index}`}
                    className={`${styles.value} ${get(el, "total_advance.primary", false)
                      ? styles.bestDeal
                      : ""
                      }`}
                  >
                    ${numberWithCommas(get(el, "total_advance.value", 0))}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell className={styles.sticky} component="th" scope="row">
                  Distributor
                </TableCell>
                {comparisionDetailList.map((el, index) => (
                  <TableCell key={`cell-${index}`} className={styles.value}>
                    <div className={styles.partnerCell}>
                      {get(el, "logo") && (
                        <img
                          src={get(el, "logo")}
                          alt={`${get(el, "label")}-logo`}
                        />
                      )}{" "}
                      {get(el, "distributor") === DEFAULT_PARTNER
                        ? "Current Distributor"
                        : get(el, "distributor")}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow className={styles.titleRow}>
                <TableCell
                  className={`${styles.sticky} ${styles.title}`}
                  colSpan={2}
                  component="th"
                  scope="row"
                >
                  Income Streams Used Towards Recopument
                </TableCell>
                {comparisionDetailList.map(
                  (el, index) =>
                    !!index && (
                      <TableCell
                        key={`cell-${index}`}
                        className={`${styles.title}`}
                      >
                        {""}
                      </TableCell>
                    ),
                )}
              </TableRow>
              <TableRow>
                <TableCell className={styles.sticky} component="th" scope="row">
                  Existing Catalog
                </TableCell>
                {comparisionDetailList.map((el, index) => (
                  <TableCell
                    key={`cell-${index}`}
                    className={`${styles.value} ${get(
                      el,
                      "income_streams_towards_recoupment.catalog.primary",
                      false,
                    )
                      ? styles.bestDeal
                      : ""
                      }`}
                  >
                    {get(
                      el,
                      "income_streams_towards_recoupment.catalog.value",
                      "",
                    )}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell className={styles.sticky} component="th" scope="row">
                  New Music
                </TableCell>
                {comparisionDetailList.map((el, index) => (
                  <TableCell
                    key={`cell-${index}`}
                    className={`${styles.value} ${get(
                      el,
                      "income_streams_towards_recoupment.new_music.primary",
                      false,
                    )
                      ? styles.bestDeal
                      : ""
                      }`}
                  >
                    {get(
                      el,
                      "income_streams_towards_recoupment.new_music.value",
                      "",
                    )}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow className={styles.titleRow}>
                <TableCell
                  className={`${styles.sticky} ${styles.title}`}
                  component="th"
                  scope="row"
                >
                  Deal Terms
                </TableCell>
                {comparisionDetailList.map((el, index) => (
                  <TableCell
                    key={`cell-${index}`}
                    className={`${styles.title}`}
                  >
                    {""}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell className={styles.sticky} component="th" scope="row">
                  Recoupment Rate
                </TableCell>
                {comparisionDetailList.map((el, index) => (
                  <TableCell
                    key={`cell-${index}`}
                    className={`${styles.value} ${get(
                      el,
                      "recoupment_and_fees.recoupment_rate.primary",
                      false,
                    )
                      ? styles.bestDeal
                      : ""
                      }`}
                  >
                    {get(el, "recoupment_and_fees.recoupment_rate.value", "")}
                  </TableCell>
                ))}
              </TableRow>

              <TableRow>
                <TableCell className={styles.sticky} component="th" scope="row">
                  Est. Time to Recoup
                </TableCell>
                {comparisionDetailList.map((el, index) => (
                  <TableCell
                    key={`cell-${index}`}
                    className={`${styles.value} ${get(
                      el,
                      "recoupment_and_fees.time_to_recoup.primary",
                      false,
                    )
                      ? styles.bestDeal
                      : ""
                      }`}
                  >
                    {get(el, "recoupment_and_fees.time_to_recoup.value", "")}
                  </TableCell>
                ))}
              </TableRow>

              <TableRow>
                <TableCell className={styles.sticky} component="th" scope="row">
                  Post-Recoupment Term
                </TableCell>
                {comparisionDetailList.map((el, index) => (
                  <TableCell
                    key={`cell-${index}`}
                    className={`${styles.value} ${get(
                      el,
                      "recoupment_and_fees.post_recoupment_term.primary",
                      false,
                    )
                      ? styles.bestDeal
                      : ""
                      }`}
                  >
                    {get(
                      el,
                      "recoupment_and_fees.post_recoupment_term.value",
                      "",
                    )}
                  </TableCell>
                ))}
              </TableRow>

              <TableRow>
                <TableCell className={styles.sticky} component="th" scope="row">
                  Income Retained During Term
                </TableCell>
                {comparisionDetailList.map((el, index) => (
                  <TableCell
                    key={`cell-${index}`}
                    className={`${styles.value} ${get(
                      el,
                      "recoupment_and_fees.income_retained_during_term.primary",
                      false,
                    )
                      ? styles.bestDeal
                      : ""
                      }`}
                  >
                    {get(
                      el,
                      "recoupment_and_fees.income_retained_during_term.value",
                      "",
                    )}
                  </TableCell>
                ))}
              </TableRow>

              <TableRow className={styles.titleRow}>
                <TableCell
                  className={`${styles.sticky} ${styles.title}`}
                  component="th"
                  scope="row"
                >
                  Services & Fees
                </TableCell>
                {comparisionDetailList.map((el, index) => (
                  <TableCell
                    key={`cell-${index}`}
                    className={`${styles.title}`}
                  >
                    {""}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell className={styles.sticky} component="th" scope="row">
                  Distribution Fee
                </TableCell>
                {comparisionDetailList.map((el, index) => (
                  <TableCell
                    key={`cell-${index}`}
                    className={`${styles.value} ${
                      get(
                        el,
                        "recoupment_and_fees.distribution_fee.primary",
                        false,
                      )
                        ? styles.bestDeal
                        : ""
                    }`}
                  >
                    {get(el, "recoupment_and_fees.distribution_fee.value", "")}
                  </TableCell>
                ))}
              </TableRow>

              <TableRow>
                <TableCell className={styles.sticky} component="th" scope="row">
                  Playlist Promotion
                </TableCell>
                {comparisionDetailList.map((el, index) => (
                  <TableCell
                    key={`cell-${index}`}
                    className={`${styles.value} ${get(el, "services.playlistPromotion", false)
                      ? styles.bestDeal
                      : ""
                      }`}
                  >
                    {get(el, "services.playlistPromotion", false) ? (
                      <TickBadgeIcon />
                    ) : (
                      renderDisabledItem(get(el, "distributor"))
                    )}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell className={styles.sticky} component="th" scope="row">
                  Digital Marketing
                </TableCell>
                {comparisionDetailList.map((el, index) => (
                  <TableCell
                    key={`cell-${index}`}
                    className={`${styles.value} ${get(el, "services.digitalMarketing", false)
                      ? styles.bestDeal
                      : ""
                      }`}
                  >
                    {get(el, "services.digitalMarketing", false) ? (
                      <TickBadgeIcon />
                    ) : (
                      renderDisabledItem(get(el, "distributor"))
                    )}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell className={styles.sticky} component="th" scope="row">
                  Synch
                </TableCell>
                {comparisionDetailList.map((el, index) => (
                  <TableCell
                    key={`cell-${index}`}
                    className={`${styles.value} ${get(el, "services.synch", false) ? styles.bestDeal : ""
                      }`}
                  >
                    {get(el, "services.synch", false) ? (
                      <TickBadgeIcon />
                    ) : (
                      renderDisabledItem(get(el, "distributor"))
                    )}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell className={styles.sticky} component="th" scope="row">
                  YouTube Monetization
                </TableCell>
                {comparisionDetailList.map((el, index) => (
                  <TableCell
                    key={`cell-${index}`}
                    className={`${styles.value} ${get(el, "services.youTubeMonetization", false)
                      ? styles.bestDeal
                      : ""
                      }`}
                  >
                    {get(el, "services.youTubeMonetization", false) ? (
                      <TickBadgeIcon />
                    ) : (
                      renderDisabledItem(get(el, "distributor"))
                    )}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell className={styles.sticky} component="th" scope="row">
                  Others
                </TableCell>
                {comparisionDetailList.map((el, index) => (
                  <TableCell key={`cell-${index}`} className={styles.value}>
                    {!!get(el, "featuresHTML") ? (
                      <div className={styles.dealButton}>
                        <button
                          className={styles.textBtn}
                          data-testid={"view-service-btn-" + index}
                          onClick={() => {
                            invoke(
                              props,
                              "toggleFeatureModal",
                              get(el, "distributor"),
                            );
                          }}
                        >
                          View Services
                        </button>
                      </div>
                    ) : (
                      <span className={styles.noService}>N/A</span>
                    )}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell
                  className={styles.sticky}
                  component="th"
                  scope="row"
                ></TableCell>
                {comparisionDetailList.map((el, index) => (
                  <TableCell key={`cell-${index}`} className={styles.value}>
                    <div className={styles.dealButton}>
                      <button
                        className={styles.primary}
                        data-testid={"select-offer-btn-" + index}
                        onClick={() =>
                          saveRecommenedCardDeal(props.dealList[index + 1])
                        }
                      >
                        Select Deal
                      </button>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const renderMobileCompareTable = () => {
    return (
      <div className={styles.mobileTableDiv}>
        <div className={styles.card}>
          <div className={styles.cardSection}>Total Advance</div>
          <div className={styles.cardDetails}>
            {comparisionDetailList.map((el, index) => (
              <div className={styles.rows}>
                <div className={styles.fields}>
                  {get(el, "logo") && (
                    <img
                      src={get(el, "logo")}
                      alt={`${get(el, "label")}-logo`}
                    />
                  )}{" "}
                  <span>
                    {get(el, "distributor") === DEFAULT_PARTNER
                      ? "Current Distributor"
                      : get(el, "distributor")}
                  </span>
                </div>
                <div
                  className={`${styles.value} ${get(el, "total_advance.primary", false)
                    ? styles.bestDeal
                    : ""
                    }`}
                >
                  ${numberWithCommas(get(el, "total_advance.value", 0))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.card}>
          <div className={`${styles.cardSection} ${styles.secondary}`}>
            Income Streams Applied to Recoupment
          </div>
          <div className={styles.title}>Existing Catalog</div>
          <div className={styles.cardDetails}>
            {comparisionDetailList.map((el, index) => (
              <div className={styles.rows}>
                <div className={styles.fields}>
                  {get(el, "logo") && (
                    <img
                      src={get(el, "logo")}
                      alt={`${get(el, "label")}-logo`}
                    />
                  )}{" "}
                  <span>
                    {get(el, "distributor") === DEFAULT_PARTNER
                      ? "Current Distributor"
                      : get(el, "distributor")}
                  </span>
                </div>
                <div
                  className={`${styles.value} ${get(
                    el,
                    "income_streams_towards_recoupment.catalog.primary",
                    false,
                  )
                    ? styles.bestDeal
                    : ""
                    }`}
                >
                  {get(
                    el,
                    "income_streams_towards_recoupment.catalog.value",
                    "",
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.title}>New Music</div>
          <div className={styles.cardDetails}>
            {comparisionDetailList.map((el, index) => (
              <div className={styles.rows}>
                <div className={styles.fields}>
                  {get(el, "logo") && (
                    <img
                      src={get(el, "logo")}
                      alt={`${get(el, "label")}-logo`}
                    />
                  )}{" "}
                  <span>
                    {get(el, "distributor") === DEFAULT_PARTNER
                      ? "Current Distributor"
                      : get(el, "distributor")}
                  </span>
                </div>
                <div
                  className={`${styles.value} ${get(
                    el,
                    "income_streams_towards_recoupment.new_music.primary",
                    false,
                  )
                    ? styles.bestDeal
                    : ""
                    }`}
                >
                  {get(
                    el,
                    "income_streams_towards_recoupment.new_music.value",
                    "",
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.card}>
          <div className={`${styles.cardSection} ${styles.secondary}`}>
            Advance & Fees
          </div>
          <div className={styles.title}>Recoupment Rate</div>
          <div className={styles.cardDetails}>
            {comparisionDetailList.map((el, index) => (
              <div className={styles.rows}>
                <div className={styles.fields}>
                  {get(el, "logo") && (
                    <img
                      src={get(el, "logo")}
                      alt={`${get(el, "label")}-logo`}
                    />
                  )}{" "}
                  <span>
                    {get(el, "distributor") === DEFAULT_PARTNER
                      ? "Current Distributor"
                      : get(el, "distributor")}
                  </span>
                </div>
                <div
                  className={`${styles.value} ${get(
                    el,
                    "recoupment_and_fees.recoupment_rate.primary",
                    false,
                  )
                    ? styles.bestDeal
                    : ""
                    }`}
                >
                  {get(el, "recoupment_and_fees.recoupment_rate.value", "")}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.title}>Est. Time to Recoup</div>
          <div className={styles.cardDetails}>
            {comparisionDetailList.map((el, index) => (
              <div className={styles.rows}>
                <div className={styles.fields}>
                  {get(el, "logo") && (
                    <img
                      src={get(el, "logo")}
                      alt={`${get(el, "label")}-logo`}
                    />
                  )}{" "}
                  <span>
                    {get(el, "distributor") === DEFAULT_PARTNER
                      ? "Current Distributor"
                      : get(el, "distributor")}
                  </span>
                </div>
                <div
                  className={`${styles.value} ${get(el, "recoupment_and_fees.time_to_recoup.primary", false)
                    ? styles.bestDeal
                    : ""
                    }`}
                >
                  {get(el, "recoupment_and_fees.time_to_recoup.value", "")}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.title}>Post-Recoupment Term</div>
          <div className={styles.cardDetails}>
            {comparisionDetailList.map((el, index) => (
              <div className={styles.rows}>
                <div className={styles.fields}>
                  {get(el, "logo") && (
                    <img
                      src={get(el, "logo")}
                      alt={`${get(el, "label")}-logo`}
                    />
                  )}{" "}
                  <span>
                    {get(el, "distributor") === DEFAULT_PARTNER
                      ? "Current Distributor"
                      : get(el, "distributor")}
                  </span>
                </div>
                <div
                  className={`${styles.value} ${get(
                    el,
                    "recoupment_and_fees.post_recoupment_term.primary",
                    false,
                  )
                    ? styles.bestDeal
                    : ""
                    }`}
                >
                  {get(
                    el,
                    "recoupment_and_fees.post_recoupment_term.value",
                    "",
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.title}>Income Retained During Term</div>
          <div className={styles.cardDetails}>
            {comparisionDetailList.map((el, index) => (
              <div className={styles.rows}>
                <div className={styles.fields}>
                  {get(el, "logo") && (
                    <img
                      src={get(el, "logo")}
                      alt={`${get(el, "label")}-logo`}
                    />
                  )}{" "}
                  <span>
                    {get(el, "distributor") === DEFAULT_PARTNER
                      ? "Current Distributor"
                      : get(el, "distributor")}
                  </span>
                </div>
                <div
                  className={`${styles.value} ${get(
                    el,
                    "recoupment_and_fees.income_retained_during_term.primary",
                    false,
                  )
                    ? styles.bestDeal
                    : ""
                    }`}
                >
                  {get(
                    el,
                    "recoupment_and_fees.income_retained_during_term.value",
                    "",
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.card}>
          <div className={`${styles.cardSection} ${styles.secondary}`}>
            Services
          </div>
          <div className={styles.title}>Distribution Fee</div>
          <div className={styles.cardDetails}>
            {comparisionDetailList.map((el, index) => (
              <div className={styles.rows}>
                <div className={styles.fields}>
                  {get(el, "logo") && (
                    <img
                      src={get(el, "logo")}
                      alt={`${get(el, "label")}-logo`}
                    />
                  )}{" "}
                  <span>
                    {get(el, "distributor") === DEFAULT_PARTNER
                      ? "Current Distributor"
                      : get(el, "distributor")}
                  </span>
                </div>
                <div
                  className={`${styles.value} ${
                    get(
                      el,
                      "recoupment_and_fees.distribution_fee.primary",
                      false,
                    )
                      ? styles.bestDeal
                      : ""
                  }`}
                >
                  {get(el, "recoupment_and_fees.distribution_fee.value", "")}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.title}>Playlist Promotion</div>
          <div className={styles.cardDetails}>
            {comparisionDetailList.map((el, index) => (
              <div className={styles.rows}>
                <div className={styles.fields}>
                  {get(el, "logo") && (
                    <img
                      src={get(el, "logo")}
                      alt={`${get(el, "label")}-logo`}
                    />
                  )}{" "}
                  <span>
                    {get(el, "distributor") === DEFAULT_PARTNER
                      ? "Current Distributor"
                      : get(el, "distributor")}
                  </span>
                </div>
                <div
                  className={`${styles.value} ${get(el, "services.playlistPromotion", false)
                    ? styles.bestDeal
                    : ""
                    }`}
                >
                  {get(el, "services.playlistPromotion", false) ? (
                    <TickBadgeIcon />
                  ) : (
                    renderDisabledItem(get(el, "distributor"))
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.title}>Digital Marketing</div>
          <div className={styles.cardDetails}>
            {comparisionDetailList.map((el, index) => (
              <div className={styles.rows}>
                <div className={styles.fields}>
                  {get(el, "logo") && (
                    <img
                      src={get(el, "logo")}
                      alt={`${get(el, "label")}-logo`}
                    />
                  )}{" "}
                  <span>
                    {get(el, "distributor") === DEFAULT_PARTNER
                      ? "Current Distributor"
                      : get(el, "distributor")}
                  </span>
                </div>
                <div
                  className={`${styles.value} ${get(el, "services.digitalMarketing", false)
                    ? styles.bestDeal
                    : ""
                    }`}
                >
                  {get(el, "services.digitalMarketing", false) ? (
                    <TickBadgeIcon />
                  ) : (
                    renderDisabledItem(get(el, "distributor"))
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.title}>Synch</div>
          <div className={styles.cardDetails}>
            {comparisionDetailList.map((el, index) => (
              <div className={styles.rows}>
                <div className={styles.fields}>
                  {get(el, "logo") && (
                    <img
                      src={get(el, "logo")}
                      alt={`${get(el, "label")}-logo`}
                    />
                  )}{" "}
                  <span>
                    {get(el, "distributor") === DEFAULT_PARTNER
                      ? "Current Distributor"
                      : get(el, "distributor")}
                  </span>
                </div>
                <div
                  className={`${styles.value} ${get(el, "services.synch", false) ? styles.bestDeal : ""
                    }`}
                >
                  {get(el, "services.synch", false) ? (
                    <TickBadgeIcon />
                  ) : (
                    renderDisabledItem(get(el, "distributor"))
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.title}>YouTube Monetization</div>
          <div className={styles.cardDetails}>
            {comparisionDetailList.map((el, index) => (
              <div className={styles.rows}>
                <div className={styles.fields}>
                  {get(el, "logo") && (
                    <img
                      src={get(el, "logo")}
                      alt={`${get(el, "label")}-logo`}
                    />
                  )}{" "}
                  <span>
                    {get(el, "distributor") === DEFAULT_PARTNER
                      ? "Current Distributor"
                      : get(el, "distributor")}
                  </span>
                </div>
                <div
                  className={`${styles.value} ${get(el, "services.youTubeMonetization", false)
                    ? styles.bestDeal
                    : ""
                    }`}
                >
                  {get(el, "services.youTubeMonetization", false) ? (
                    <TickBadgeIcon />
                  ) : (
                    renderDisabledItem(get(el, "distributor"))
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.title}>Others</div>
          <div className={styles.cardDetails}>
            {comparisionDetailList.map((el, index) => (
              <div className={styles.rows}>
                <div className={styles.fields}>
                  {get(el, "logo") && (
                    <img
                      src={get(el, "logo")}
                      alt={`${get(el, "label")}-logo`}
                    />
                  )}{" "}
                  <span>
                    {get(el, "distributor") === DEFAULT_PARTNER
                      ? "Current Distributor"
                      : get(el, "distributor")}
                  </span>
                </div>
                <div className={`${styles.buttonCtn}`}>
                  {!!get(el, "featuresHTML") ? (
                    <button
                      className={styles.textBtn}
                      data-testid={"mobile-view-service-btn-" + index}
                      onClick={() => {
                        invoke(
                          props,
                          "toggleFeatureModal",
                          get(el, "distributor"),
                        );
                      }}
                    >
                      View Services
                    </button>
                  ) : (
                    <span className={styles.noService}>N/A</span>
                  )}
                  <button
                    className={styles.primary}
                    data-testid={"mobile-select-offer-btn-" + index}
                    onClick={() =>
                      saveRecommenedCardDeal(props.dealList[index + 1])
                    }
                  >
                    Select Deal
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={styles.comapreDealContainer}>
      <div className={styles.headerContainer}>
        <div className={`d-none ${styles.sliderCtn}`}>
          <DropDownArrow
            data-testid="previous-step"
            className={`${currentStep === COMPARE_DEAL_STEPS[0] ? styles.disabled : ""
              }`}
            onClick={() => {
              setCurrentStep(COMPARE_DEAL_STEPS[0]);
              setGoBack(true);
            }}
          />
          <DropDownArrow
            data-testid="next-step"
            className={`${currentStep === COMPARE_DEAL_STEPS[1] ? styles.disabled : ""
              }`}
            onClick={() => {
              setCurrentStep(COMPARE_DEAL_STEPS[1]);
              setGoBack(false);
            }}
          />
        </div>
      </div>
      <TransitionGroup
        style={{ minHeight: `${currentStep === COMPARE_DEAL_STEPS[0] ? swipeDivHeight : chartDivHeight}px` }}
        className={`${styles.transitionContainer} ${goBack ? styles.back : ""}`}
      >
        <CSSTransition
          key={`compare-steps-${currentStep}`}
          timeout={2000}
          classNames={"compare-step-swipe"}
        >
          <div ref={swipeDivRef} className={styles.swipe}>
            {currentStep === COMPARE_DEAL_STEPS[0] ? (
              <>
                <div className={styles.titleCtn}>
                  <h2>Let’s Break it down</h2>
                  <p>
                    The following deal summary is a breakdown of some key metrics we
                    think you should review.
                  </p>
                </div>
                {renderDesktopCompareTable()}
                {renderMobileCompareTable()}
              </>
            ) : (
              <CompareDealChart setChartDivHeight={setChartDivHeight} data={comparisionDetailList} />
            )}
          </div>
        </CSSTransition>
      </TransitionGroup>
      <div className={styles.footerContainer}>
        <span
          data-testid="go-back"
          onClick={() =>
            invoke(props, "setParentState", {
              compareOffers: false,
            })
          }
        >
          <DropDownArrow /> Return to Offers
        </span>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default CompareRecommendedDeals;
