import React, { useEffect, useState } from "react";
import { get, invoke } from "lodash";
import styles from "./ConfirmedOffersV2.module.scss";
import Select from "react-select";
import { distributorSelectStyle } from "../../../component/UploadFiles/selectStyle";
import { ReactComponent as DropDownArrow } from "../../../assets/logos/dropDownArrow.svg";
import Tooltip from "../../../component/Tooltip/Tooltip";
import { numberWithCommas } from "../YourAdvance/helper";
import segment from "../../../utils/segment";
import { ReactComponent as ToolTipIcon } from "../../../assets/logos/help-icon.svg";
import ReactModal from "react-modal";
import {
  OFFER_KEYS,
  YT_URL,
  YT_VIDEO_DESC,
} from "../ConfirmedOffers/constants";
import { GetIframeVideo } from "../helper";
import { getSubDomain } from "../../../component/ThemeManager/helper";
import { BB_SUBDOMAINS } from "../../../component/ThemeManager/constants";
import { DEFAULT_PARTNER } from "../YourAdvance/constants";

const RecommendedDealCard = (props) => {
  const [distributor, setDistributor] = useState();
  const [distributorList, setDistributorList] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const [payloadDetails, setPayloadDetails] = useState({});
  const [offerDetails, setOfferDetails] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [videoModal, setVideoModal] = useState({
    isModalOpen: false,
    videoTitle: "",
    videoDescription: "",
    ytUrl: "",
  });

  const onDistributorChange = (option) => {
    const offerWithPartner = offerList.find(
      (offer) => offer.partner === option.value,
    );
    const distributorOption = distributorList.find(
      (val) => val.value === option.value,
    );
    setDistributor(distributorOption);
    setOfferDetails(offerWithPartner);
    setPayloadDetails((prev) => ({ ...prev, partner: get(option, "value") }));
    invoke(props, "selectRecommendedDeal", offerWithPartner, props.card);
  };

  const saveRecommenedCardDeal = (isCustomizeOffer) => {
    let payload = offerDetails;
    if (isCustomizeOffer) {
      payload = payloadDetails;
      invoke(segment, "track.proceedCustomizeOffer");
    } else {
      invoke(segment, "track.selectedFinalOffer");
    }
    invoke(props, "saveRecommenedDeal", payload, isCustomizeOffer);
    return true;
  };

  useEffect(() => {
    if (props?.offers) {
      setOfferList(props.offers);
      setPayloadDetails({
        works: get(props, "offers.0.works"),
        royalty: get(props, "offers.0.royalty"),
        tail_term: get(props, "offers.0.tail_term"),
        tail_rev_share: get(props, "offers.0.tail_rev_share"),
        flow_through: get(props, "offers.0.flow_through"),
      });
      const distributorList = [];
      props.offers.forEach((ele) => {
        distributorList.push({
          label:
            ele.partner === DEFAULT_PARTNER
              ? "Current Distributor"
              : ele.partner,
          value: ele.partner,
          logo: ele.logo,
        });
      });
      setDistributorList(distributorList);
      if (props.selectedDistributor) {
        const distributorOption = distributorList.find(
          (val) => val.value === props.selectedDistributor,
        );
        const offerWithPartner = props.offers.find(
          (offer) => offer.partner === props.selectedDistributor,
        );
        setDistributor(distributorOption);
        setOfferDetails(offerWithPartner);
        setPayloadDetails((prev) => ({
          ...prev,
          partner: get(props, "selectedDistributor"),
        }));
      }
    }
  }, [props.offers]);

  const handleCloseModal = () => {
    setVideoModal((prev) => ({ ...prev, isModalOpen: false, videoTitle: "" }));
  };

  const getYTUrl = (title) => {
    let ytUrl;
    if (BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1) {
      ytUrl = YT_URL[title].BB;
    } else {
      ytUrl = YT_URL[title].CC;
    }
    return ytUrl;
  };

  const renderVideoContainer = (title) => {
    const index = OFFER_KEYS.indexOf(title);
    const ytUrl = getYTUrl(Object.keys(YT_URL)[index]);
    const videoTitle = OFFER_KEYS[index];
    const videoDescription = YT_VIDEO_DESC[index];
    setVideoModal((prev) => ({
      ...prev,
      isModalOpen: true,
      videoDescription,
      videoTitle,
      ytUrl,
    }));
  };

  const renderModal = () => {
    return (
      <ReactModal
        isOpen={get(videoModal, "isModalOpen")}
        shouldCloseOnEsc
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick
        overlayClassName={styles.modalOverlay}
        className={styles.detailsPopupContainer}
      >
        <div className={styles.videoContainer}>
          <h2>{get(videoModal, "videoTitle")}</h2>
          <span className={styles.closeBtn} onClick={handleCloseModal}>
            &times;
          </span>
          <div className={styles.video} style={{ cursor: "pointer" }}>
            <GetIframeVideo url={get(videoModal, "ytUrl")} />
          </div>
          <p>{get(videoModal, "videoDescription")}</p>
        </div>
      </ReactModal>
    );
  };

  return (
    <div className={styles.dealCard}>
      <div className={styles.dealHeader}>
        <div className={styles.label}>Total Advance</div>
        <div className={styles.totalAdvance}>
          ${numberWithCommas(offerDetails?.total_advance)}
        </div>
      </div>
      <div className={styles.dealDetails}>
        <ul>
          <li>
            <span className={styles.label}>
              Works
              <span
                data-testid="WorksTooltip"
                onClick={() => renderVideoContainer(OFFER_KEYS[0])}
              >
                <ToolTipIcon />
              </span>
            </span>
            <span className={styles.value}>
              {offerDetails ? offerDetails.works : offerList[0]?.works}
            </span>
          </li>
          <li>
            <span className={styles.label}>Est. Time to Recoup</span>
            <span className={styles.value}>
              {offerDetails?.recoup_year_lower} -{" "}
              {offerDetails?.recoup_year_higher} Years
            </span>
          </li>
          <div
            className={`${styles.moreDetailContainer} ${
              showMore ? styles.active : ""
            } `}
          >
            <li>
              <span className={styles.label}>
                Recoupment Rate
                <span
                  data-testid="RecoupmentTooltip"
                  onClick={() => renderVideoContainer(OFFER_KEYS[2])}
                >
                  <ToolTipIcon />
                </span>
              </span>
              <span className={styles.value}>{offerDetails?.royalty}</span>
            </li>
            <li>
              <span className={styles.label}>
                Post-Recoup Share
                <span
                  data-testid="TermLengthTooltip"
                  onClick={() => renderVideoContainer(OFFER_KEYS[3])}
                >
                  <ToolTipIcon />
                </span>
              </span>
              <span className={styles.value}>
                {offerDetails?.tail_rev_share}
                {offerDetails?.tail_term
                  ? ` for ${offerDetails?.tail_term} Years`
                  : ""}
              </span>
            </li>
            <li>
              <span className={`${styles.label} ${styles.textTooltip}`}>
                Distribution Fee
                <Tooltip
                  place="top"
                  light
                  id="distributionFee"
                  content={
                    "This is the annual cost charged by the distributor for their services."
                  }
                  delay={200}
                />
              </span>
              <span className={styles.value}>
                {" "}
                {offerDetails?.fees || "None"}
              </span>
            </li>
            <li>
              <span className={styles.label}>Upfront Advance</span>
              <span className={`${styles.value} ${styles.amount}`}>
                ${numberWithCommas(offerDetails?.catalog_advance)}
              </span>
            </li>
            <li>
              <span className={styles.label}>New Release Advance</span>
              <span className={`${styles.value} ${styles.amount}`}>
                ${numberWithCommas(offerDetails?.nr_advance)}
              </span>
            </li>
            <li>
              <span className={styles.label}>Option Advance</span>
              <span className={`${styles.value} ${styles.amount}`}>
                ${numberWithCommas(offerDetails?.option_advance)}
              </span>
            </li>
          </div>
        </ul>
        <div
          className={`${styles.collapseArrow} ${showMore && styles.arrowDown} `}
          onClick={() => setShowMore(!showMore)}
        >
          <span className={styles.showMore}>
            {" "}
            {showMore ? "Hide Details" : "Show More Details"}
          </span>
          <DropDownArrow />
        </div>
      </div>
      <div className={`${styles.dealPartner} ${props.isBBOnlyPartner && "d-none"}`}>
        {distributorList.length > 1 ? (
          <Select
            classNamePrefix="distributor"
            styles={distributorSelectStyle}
            onChange={onDistributorChange}
            options={distributorList}
            placeholder={`Select a Distributor`}
            value={distributor}
            components={{ IndicatorSeparator: null }}
            formatOptionLabel={(val) => (
              <div className={styles.option}>
                {get(val, "logo") && (
                  <img
                    src={get(val, "logo")}
                    alt={`${get(val, "label")}-logo`}
                  />
                )}{" "}
                <span>{get(val, "label")}</span>
              </div>
            )}
            aria-label="partner-select-input"
          />
        ) : (
          <div className={styles.partnerDetails}>
            <span className={styles.label}>Distributor</span>
            <span className={styles.value}>
              {get(distributor, "logo") && (
                <img
                  src={get(distributor, "logo")}
                  alt={`${get(distributor, "label")}-logo`}
                />
              )}{" "}
              {get(offerDetails, "partner")}{" "}
            </span>
          </div>
        )}
        {!!get(offerDetails, "featuresHTML") ? (
          <div className={styles.featureCtn}>
            <button
              className={styles.featureBtn}
              data-testid="view-feature-btn"
              onClick={() => {
                invoke(
                  props,
                  "toggleFeatureModal",
                  get(offerDetails, "partner"),
                );
              }}
            >
              View Distributor Features
            </button>
          </div>
        ) : (
          <div className={styles.emptyDiv}></div>
        )}
      </div>
      <div className={`${styles.dealButton} ${props.isBBOnlyPartner && "mt-auto"} `}>
        <button
          className={styles.primary}
          data-testid="select-offer-btn"
          disabled={!distributor}
          onClick={() => saveRecommenedCardDeal(false)}
        >
          Select Offer
        </button>
        <button
          className={styles.secondary}
          data-testid="customize-offer-btn"
          onClick={() => saveRecommenedCardDeal(true)}
        >
          Customize Offer
        </button>
      </div>
      {renderModal()}
    </div>
  );
};

export default RecommendedDealCard;
