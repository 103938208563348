export const DEFAULT_FLOW_THROUGH = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90];

export const DEFAULT_TERMS = [1, 2, 3, 5, 8, 20];

export const DEFAULT_ROYALTY = [50, 60, 70, 80, 90];

export const OFFER_KEYS = [
  "Deal Type",
  "Income Paid Through Before Recoupment",
  "Recoupment Rate",
  "Post-Recoupment Term",
  "Income Paid Through After Recoupment",
  "Distributor",
];

export const YT_URL = {
  WORKS: {
    BB: "https://www.youtube.com/watch?v=MHhC5N2xsSs",
    CC: "https://www.youtube.com/watch?v=QYsgi8Fo2EI",
  },
  INCOME_PAID_BEFORE_RECOUP: {
    BB: "https://www.youtube.com/watch?v=c11dVWSmkvc",
    CC: "https://www.youtube.com/watch?v=ahr9ECwIUqc",
  },
  RECOUPMENT_RATE: {
    BB: "https://www.youtube.com/watch?v=iQL6lRaFbp4",
    CC: "https://www.youtube.com/watch?v=3ETarjdNcSs",
  },
  TERM_LENGTH: {
    BB: "https://www.youtube.com/watch?v=yNS3QFAGURo",
    CC: "https://www.youtube.com/watch?v=ejpkGtxw-7w",
  },
  INCOME_PAID_AFTER_RECOUP: {
    BB: "https://www.youtube.com/watch?v=yNS3QFAGURo",
    CC: "https://www.youtube.com/watch?v=ejpkGtxw-7w",
  },
  DISTRIBUTOR: {
    BB: "https://www.youtube.com/watch?v=lJcbHC5k8MY",
    CC: "https://www.youtube.com/watch?v=OTbt0ojFirQ",
  },
};

export const YT_VIDEO_DESC = [
  "“Included Works” is the music you want to receive an advance against. Catalog refers to previously released music while new music refers to songs that have not been released yet. Including new music will increase your advance.",
  "The percentage of your monthly earnings that you can continue to receive. This income will be paid to you each month but will decrease your advance.",
  "The percentage of earnings that goes towards paying back the advance. The lower the recoupment rate, the higher your advance.",
  "The amount of time you will share your earnings with us after recoupment. This term does not start until all advances are fully recouped. Increasing this term will increase your advance.",
  "The percentage of earnings that will be paid to you during the post-recoupment term. Decreasing this percentage will increase your advance.",
  "If you choose your current distributor, you won't need to move any of your tracks, but those will be the lowest advance offers available. If you choose a new distributor for a higher advance amount, you'll need to transfer your catalog to that distributor to receive your advance. Our team will assist with the transfer, but you will need to have all your assets (music, artwork, etc) gathered.",
];

export const FIXED_DEALS = [
  "Long Term Catalog Deal",
  "20 Year Deal",
  "20 Year Catalog Deal",
];
