import { ReactComponent as DownloadLogo } from "../../../assets/logos/download-arrow-line.svg";
import { ReactComponent as GroupMail } from "../../../assets/logos/GroupMail.svg";
import { ReactComponent as DocumentIcon } from "../../../assets/logos/document-icon.svg";

export const ACCEPT_OFFER_TITLE = "Review Deal Summary";

export const UNITED_STATES_COUNTRY = "united states";

export const OFFER_ACTION_TEXT = `Complete our onboarding process to provide all the necessary legal information to generate your final contract.`;
export const POST_OFFER_ACTION_TEXT = `Look out for an email from Docusign with your contract.`;
export const ASSETS = {
  DownloadLogo,
  GroupMail,
  DocumentIcon,
};

export const MICRO_DEPOSIT_ACTION_TEXT = `* By continuing, you authorize two micro deposits in the amount of $0.99 or less into the account you confirmed within the next few days. We will withdraw what we deposited. To avoid bank fees, makes sure you have at least $1.98 in your account.`;

export const ENTITY_OPTIONS = [
  { label: "The Artist", value: "artist" },
  { label: "The Artist's Representative", value: "representative" },
];
export const BOOLEAN_RADIO = [
  { label: "Yes", value: "1" },
  { label: "No", value: "0" },
];
export const FOUND_US_OPTIONS = [
  { label: "An advertisement", value: "advertisement" },
  { label: "A friend", value: "friend" },
  { label: "A music associate", value: "associate" },
  { label: "Other", value: "other" },
];

export const ONBOARDING_INITIAL_DATA = {
  entityType: "",
  rightHolderName: "",
  representativeName: "",
  artistName: "",
  representativeEmail: "",
  artistEmail: "",
  phone: "",
  countryCode: "",
  isCurrentAdvanceWithOtherPartner: null,
  distributionAgreement: null,
  publicityPhoto: null,
  type: "",
  companyIncorporated: "",
};
export const FORM_STEPS = {
  STEP1: "step1",
  STEP2: "step2",
  STEP3: "step3",
  STEP4: "step4",
  STEP_ACK: "stepAcknowledgement",
};

export const ONBOARD_ARTIST_STEPS = {
  [FORM_STEPS.STEP1]: { entityType: "" },
  [FORM_STEPS.STEP2]: {
    type: "",
    rightHolderName: "",
    artistName: "",
    artistEmail: "",
    phone: "",
    countryCode: "",
    isCurrentAdvanceWithOtherPartner: null,
    companyIncorporated: "",
  },
  [FORM_STEPS.STEP3]: {
    distributionAgreement: null,
    publicityPhoto: null,
  },
};

export const ONBOARD_REPRESENTATIVE_STEPS = {
  [FORM_STEPS.STEP1]: { entityType: "" },
  [FORM_STEPS.STEP2]: {
    representativeName: "",
    representativeEmail: "",
  },
  [FORM_STEPS.STEP3]: {
    type: "",
    rightHolderName: "",
    artistName: "",
    artistEmail: "",
    phone: "",
    countryCode: "",
    isCurrentAdvanceWithOtherPartner: null,
    companyIncorporated: "",
  },
  [FORM_STEPS.STEP4]: {
    distributionAgreement: null,
    publicityPhoto: null,
  },
};

export const TRACK_OWNER_OPTIONS = [
  { label: "Individual", value: "individual" },
  { label: "Company", value: "company" },
];

export const PLAID_WINDOW_CHECK_TIMER = 3000;
export const TRACK_OWN_OPTIONS = [
  { label: "The Artist", value: "artist" },
  { label: "A Company", value: "company" },
  { label: "A Label", value: "label" },
  { label: "Other", value: "other" },
];
export const GUIDE_URL = {
  W8_BEN: {
    label: "W8-BEN",
    url: "https://www.irs.gov/pub/irs-pdf/fw8ben.pdf",
  },
  W9_US: { label: "W9", url: "https://www.irs.gov/pub/irs-pdf/fw9.pdf" },
  W8_BEN_E: {
    label: "W8-BEN-E",
    url: "https://www.irs.gov/pub/irs-pdf/fw8bene.pdf",
  },
};

export const COUNTRY_USA = "United States of America";

export const ONBOARDING_NOTE = `NOTE: By proceeding to onboarding, you will no longer be able to change your deal structures. After you’ve completed the form, you should receive a contract from us in 1-3 business days.`;

export const ACCEPT_OFFER_ONBOARDING_BTN_TEXT = "Accept Offer and Begin Onboarding"

export const ACCEPT_OFFER_MONDAYLY_BTN_TEXT = "Proceed to form";

export const EDIT_BANK_DETAILS = "Accept Offer and Confirm Banking Details";
