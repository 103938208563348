import React, { Component } from "react";
import { get, invoke, set } from "lodash";
import {
  setTitle,
  getPartnerName,
  getSubDomain,
} from "../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import styles from "./AcceptOffer.module.scss";
import confirmedStyles from "../ConfirmedOffers/ConfirmedOffers.module.scss";
import LoginHeader from "../../../component/LoginHeader";
import HorizontalMenu from "../../../component/HorizontalMenu";
import Loader from "../../../component/Loader";
import {
  API_URL,
  USER_API,
  ARTIST_API,
  TOP_TRACK,
  OFFER_CONTRACT_PDF,
  OFFER_SUMMARY,
  ERROR_SERVER_DOWN,
  OFFER_SUMMARY_API,
  ONBOARDING_DETAILS,
  EDIT_BANK_EMAIL,
} from "../constants";
import request from "../../../utils/request";
import { toast } from "react-toastify";
import { GetErrorMessage, GetIframeVideo } from "../helper";
import ContactPopup from "../../../component/ContactPopup";
import {
  ACCEPT_OFFER_TITLE,
  UNITED_STATES_COUNTRY,
  OFFER_ACTION_TEXT,
  ASSETS,
  POST_OFFER_ACTION_TEXT,
  ENTITY_OPTIONS,
  ONBOARDING_NOTE,
  BOOLEAN_RADIO,
  ACCEPT_OFFER_MONDAYLY_BTN_TEXT,
  ACCEPT_OFFER_ONBOARDING_BTN_TEXT,
  EDIT_BANK_DETAILS,
} from "./constants";
import dataURItoBlob from "../MyAccount/dataURItoBlob";
import classes from "../YourAdvance/YourAdvance.module.scss";
import moment from "moment";
import { ReactComponent as WarningIcon } from "../../../assets/logos/warning.svg";
import { ReactComponent as TextMessage } from "../../../assets/logos/np_text-message.svg";
import { ReactComponent as ToolTipContent } from "../../../assets/logos/IcontooltipIcon.svg";
import { ReactComponent as EmailClosedIcon } from "../../../assets/logos/EmailClosed.svg";
import segment from "../../../utils/segment";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import ReactModal from "react-modal";
import OnboardForm from "./OnboardingForm";
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-default.css";
import Tooltip from "../../../component/Tooltip/Tooltip";
import { ReactComponent as ToolTipIcon } from "../../../assets/logos/help-icon.svg";
import {
  OFFER_KEYS,
  YT_URL,
  YT_VIDEO_DESC,
} from "../ConfirmedOffers/constants";
import { BB_SUBDOMAINS } from "../../../component/ThemeManager/constants";
import { DEFAULT_PARTNER } from "../YourAdvance/constants";
import { getModifiedValue } from "../YourAdvance/helper";

class AcceptOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      submitLoader: false,
      offerStage: {},
      offer: {},
      pdfUrl: "",
      pdfLoading: false,
      isContactOpen: false,
      isLocationUS: false,
      isAccepted: false,
      navigationStatus: {},
      onboardData: {},
      onboardingModal: false,
      onboardEntityType: "",
      agentCode: null,
      totalPDFPage: 1,
      isRejectOfferModalOpen: false,
      partnerDetails: {},
      isModalOpen: false,
      videoTitle: null,
      videoDescription: null,
      ytUrl: null,
      trolleyRecipientEmail: "",
      isBankUpdateEmailSent: false,
      isBankEmailSentModelOpen: false,
    };
    this.navigationCallback = null;
  }

  componentDidUpdate() {
    setTitle(ACCEPT_OFFER_TITLE, this.context);
  }

  componentDidMount() {
    this.getAdvanceData();
  }

  getAdvanceData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}?isTopTrackDataNeeded=false&isLocationNeeded=true`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res && res.status) {
          this.setState(
            {
              offerStage: res.data.offerStage,
              partnerDetails: res.data.partnerDetails,
              offer: {
                ...get(res.data, "offer", {}),
                ...getModifiedValue(
                  get(res.data, "offer", {}),
                  get(res.data, "offer.cashflow_option_selected", ""),
                ),
              },
              isLocationUS:
                get(res, "data.userCountry", "").toLowerCase() ===
                UNITED_STATES_COUNTRY,
              artistProfile: {
                url: get(
                  res.data,
                  `profilePicture[${
                    get(res.data, "profilePicture", [{}]).length - 1
                  }].url`,
                  "",
                ),
                name: res.data.name,
              },
              isBankUpdateEmailSent: get(
                res,
                "data.isBankUpdateEmailSent",
                false,
              ),
              trolleyRecipientEmail: get(res, "data.trolleyRecipientEmail", ""),
            },
            this.getOfferPdfData,
          );

          this.checkSteps(
            res.data,
            get(res.data, "offerStage.verificationStatus"),
            get(res.data, "offer_approved", undefined),
          );
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
        this.props.history.push(ERROR_SERVER_DOWN);
      });
  };

  checkSteps = (data, verificationStatus, isOfferApproved) => {
    if (!isOfferApproved || get(data, "offerStage.defaultOffer.isAccepted")) {
      this.setState({ isAccepted: true });
      return false;
    }
    if (!verificationStatus.offerSummary) {
      this.props.history.push({
        pathname: OFFER_SUMMARY,
        state: { reviewData: data },
      });
    }
    return true;
  };

  getOfferPdfData = () => {
    this.setState({ pdfLoading: true });
    const data = {
      method: "POST",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${OFFER_CONTRACT_PDF}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ pdfLoading: false });
        if (res.status) {
          let blob = {};
          let pdfUrl = "";
          if (
            res.data.approved_offer_pdf &&
            get(res.data, "approved_offer_pdf", "").includes("base64")
          ) {
            blob = dataURItoBlob(res.data.approved_offer_pdf);
            pdfUrl = URL.createObjectURL(blob);
          }
          this.setState({
            pdfUrl,
          });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  downloadOffer = () => {
    const a = document.createElement("a");
    a.href = this.state.pdfUrl;
    a.download = this.state.pdfUrl.split("/").pop() || "offer.pdf";
    a.style.display = "none";
    a.target = "_blank";
    document.body.append(a);
    a.click();
    document.body.removeChild(a);
  };

  pdfLoading = () => (
    <div className={`d-flex justify-content-center`}>
      <div className={`spinner-border spinner-border-sm`} />
    </div>
  );

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ totalPDFPage: numPages });
  };

  onPDFLoadError = (err) => {
    console.error("PDF Loading Error ", err);
  };

  renderPDFLoad = () => {
    return (
      <div className={styles.pdfLoading}>
        <i className={`spinner-border`}></i>
      </div>
    );
  };

  handleContactToggle = () => {
    this.setState({ isContactOpen: !this.state.isContactOpen });
  };

  setNavigationStatus = (status, callback) => {
    this.setState({ navigationStatus: status, email: get(status, "email") });
    this.navigationCallback = callback;
    this.getOnboardingDetails();
    !status.adjustTerms && this.props.history.push(OFFER_SUMMARY);
  };

  updateUserData = (selectedArtist) => {
    this.setState({
      agentCode: selectedArtist.agent_code,
      phone: get(selectedArtist, "phone"),
    });
  };

  handleURLLocation = () => {
    window.location.href = this.state.isLocationUS
      ? get(this.context, "getContractUrl.nonInternationalUrl", "")
      : get(this.context, "getContractUrl.internationalUrl", "");
  };

  isFormIncomplete = () =>
    (get(this.state, "onboardData.artistName") ||
      get(this.state, "onboardData.representativeEmail")) &&
    !get(this.state, "navigationStatus.isAccepted");

  toggleOnboardingModal = () => {
    if (this.state.onboardingModal) {
      this.getOnboardingDetails();
      invoke(this, "navigationCallback");
    }
    this.setState({ onboardingModal: !this.state.onboardingModal });
  };

  proceedToOnboarding = () => {
    invoke(
      segment,
      "track.proceededToOnboarding",
      get(this.state, "navigationStatus.renderNewConfirmedOffers"),
    );
    if (!this.isFormIncomplete()) {
      this.toggleOnboardingModal();
      return false;
    }
    this.setState({ submitLoader: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${ONBOARDING_DETAILS}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ submitLoader: false });
        if (get(res, "status")) {
          const onboardData = get(res, "data", {});
          this.setOnboardData(res, onboardData);
          this.setState({ onboardData }, this.toggleOnboardingModal);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ submitLoader: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  handleMondayFormRedirection = () => {
    this.setState({ submitLoader: true });
    invoke(
      segment,
      "track.proceededToOnboarding",
      get(this.state, "navigationStatus.renderNewConfirmedOffers"),
    );
    const payload = { isMondayFormSubmitted: true };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${OFFER_SUMMARY_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          submitLoader: false,
        });
        if (get(res, "status")) {
          this.handleURLLocation();
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ submitLoader: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  setOnboardData = (res, onboardData) => {
    if (
      !get(onboardData, "artistEmail") &&
      !get(onboardData, "representativeEmail")
    ) {
      set(onboardData, "artistEmail", get(this.state, "email", ""));
    }
    if (!get(onboardData, "phone")) {
      set(
        onboardData,
        "phone",
        `${get(this.state, "phone.countryCode")}${get(
          this.state,
          "phone.value",
        )}`,
      );
      set(onboardData, "countryCode", get(this.state, "phone.countryCode"));
    } else {
      set(
        onboardData,
        "phone",
        `${get(res, "data.countryCode")}${get(res, "data.phone")}`,
      );
      set(onboardData, "countryCode", get(res, "data.countryCode"));
    }
    if (get(onboardData, "artistName")) {
      set(
        onboardData,
        "isCurrentAdvanceWithOtherPartner",
        !get(onboardData, "isCurrentAdvanceWithOtherPartner")
          ? BOOLEAN_RADIO[1].value
          : BOOLEAN_RADIO[0].value,
      );
    }
  };

  getOnboardingDetails = () => {
    this.setState({ submitLoader: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${ONBOARDING_DETAILS}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ submitLoader: false });
        if (res && res.status) {
          const onboardData = get(res, "data", {});
          this.setOnboardData(res, onboardData);
          this.setState({ onboardData });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ submitLoader: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          { className: "toast_hidden" },
        );
      });
  };

  onboardFormModal = () => {
    return (
      <ReactModal
        isOpen={this.state.onboardingModal}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        onRequestClose={this.toggleOnboardingModal}
        className={styles.countryModal}
        overlayClassName={`${styles.modalOverlay} ${styles.mobileOverLay}`}
      >
        <OnboardForm
          onboardData={this.state.onboardData}
          handleClose={this.toggleOnboardingModal}
        />
      </ReactModal>
    );
  };

  getYTURL = (title) => {
    let ytUrl;
    if (BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1) {
      ytUrl = YT_URL[title].BB;
    } else {
      ytUrl = YT_URL[title].CC;
    }
    return ytUrl;
  };

  renderVideoContainer = (title) => {
    const index = OFFER_KEYS.indexOf(title);
    const videoTitle = OFFER_KEYS[index];
    const ytUrl = this.getYTURL(Object.keys(YT_URL)[index]);
    const videoDescription = YT_VIDEO_DESC[index];
    this.setState({ isModalOpen: true, videoTitle, videoDescription, ytUrl });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false, videoTitle: "" });
  };

  renderModal = () => {
    return (
      <ReactModal
        isOpen={this.state.isModalOpen}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={this.handleCloseModal}
        className={confirmedStyles.detailsPopupContainer}
        overlayClassName={confirmedStyles.modalOverlay}
      >
        <div className={confirmedStyles.videoContainer}>
          <h2>{this.state.videoTitle}</h2>
          <span
            className={confirmedStyles.closeBtn}
            onClick={this.handleCloseModal}
          >
            {" "}
            &times;
          </span>
          <div className={confirmedStyles.video} style={{ cursor: "pointer" }}>
            <GetIframeVideo url={this.state.ytUrl} />
          </div>
          <p>{this.state.videoDescription}</p>
        </div>
      </ReactModal>
    );
  };

  updateBankDetails = () => {
    this.setState({ submitLoader: true });
    const data = {
      method: "POST",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${EDIT_BANK_EMAIL}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          submitLoader: false,
        });
        if (get(res, "status")) {
          this.setState((prevState) => ({
            isBankEmailSentModelOpen: true,
            isBankUpdateEmailSent: true,
            trolleyRecipientEmail: get(res, "data.email", ""),
            navigationStatus: {
              ...prevState.navigationStatus,
              isAccepted: true,
            },
          }));
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ submitLoader: false });
        toast.error(
          (toastProps) => <GetErrorMessage err={err} toastProps={toastProps} />,
          {
            className: "toast_hidden",
          },
        );
      });
  };

  handleBankEmailSentCloseModal = () => {
    this.setState({ isBankEmailSentModelOpen: false });
  };

  renderBankUpdateEmailSentModal = () => {
    return (
      <ReactModal
        isOpen={this.state.isBankEmailSentModelOpen}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={this.handleBankEmailSentCloseModal}
        className={confirmedStyles.emailSentPopupContainer}
        overlayClassName={confirmedStyles.modalOverlay}
      >
        <div className={confirmedStyles.emailSentContainer}>
          <div className={confirmedStyles.contentContainer}>
            <span
              className={confirmedStyles.closeBtn}
              onClick={this.handleBankEmailSentCloseModal}
            >
              {" "}
              &times;
            </span>
            <EmailClosedIcon />
            <h4>Link Sent!</h4>
            <div className={confirmedStyles.textContainer}>
              <p>
                Thank you for the request! We’ve sent a sign in link to{" "}
                <a
                  href={`mailto:${get(this.state, "trolleyRecipientEmail", "")}`}
                >
                  {get(this.state, "trolleyRecipientEmail", "")}
                </a>{" "}
                for you to securely update your banking information.
              </p>
              <p>
                If this is not the correct email, please email us at{" "}
                <a href={`mailto:hello@${getPartnerName()}.com`}>
                  hello@{getPartnerName()}.com
                </a>
              </p>
            </div>
          </div>
          <div className={confirmedStyles.btnContainer}>
            <button
              className={`${confirmedStyles.primaryBtn}`}
              onClick={this.handleBankEmailSentCloseModal}
            >
              Ok
            </button>
          </div>
        </div>
      </ReactModal>
    );
  };

  getButtonText = () => {
    let buttonText;
    switch (true) {
      case get(this.state.navigationStatus, "isAccepted") &&
        get(this.state.navigationStatus, "isMondayFlow"):
        buttonText = ACCEPT_OFFER_MONDAYLY_BTN_TEXT;
        break;
      case !get(this.state.navigationStatus, "isAccepted") &&
        get(this.state.navigationStatus, "isMondayFlow"):
      case !get(this.state.navigationStatus, "isAccepted") &&
        !get(this.state.navigationStatus, "isMondayFlow"):
        buttonText = ACCEPT_OFFER_ONBOARDING_BTN_TEXT;
        break;
      default:
        buttonText = ACCEPT_OFFER_ONBOARDING_BTN_TEXT;
    }
    return buttonText;
  };

  actionButtonContainer = () => {
    if (
      get(this.state, "navigationStatus.is_refinance", false) &&
      !get(this.state, "isBankUpdateEmailSent", false)
    ) {
      return (
        <div
          className={`${styles.pdfContractContainer} ${styles.getContractContainer} ${styles.mobileBtnContainer}`}
        >
          <button
            className={`${styles.primary}`}
            data-testid="updateBankBtn"
            onClick={this.updateBankDetails}
          >
            {EDIT_BANK_DETAILS}
          </button>
        </div>
      );
    }
    if (
      get(this.state, "navigationStatus.is_refinance", false) &&
      get(this.state, "isBankUpdateEmailSent", false)
    ) {
      return (
        <div
          className={`${styles.pdfContractContainer} ${styles.getContractContainer}`}
        >
          <p className={styles.bold}>Don’t see an email?</p>
          <button
            className={`${styles.secondary}`}
            data-testid="contactBtn"
            onClick={this.handleContactToggle}
          >
            <TextMessage />
            <span>Contact Us</span>
          </button>
        </div>
      );
    }
    if (
      get(this.state, "navigationStatus.isMondayFlow") ||
      (!(
        get(this.state, "onboardData.artistName") ||
        get(this.state, "onboardData.representativeEmail")
      ) &&
        !get(this.state, "navigationStatus.isAccepted"))
    ) {
      return (
        <div
          className={`${styles.pdfContractContainer} ${styles.getContractContainer} ${styles.mobileBtnContainer}`}
        >
          <button
            className={`${styles.primary}`}
            onClick={
              !get(this.state.navigationStatus, "isMondayFlow")
                ? this.proceedToOnboarding
                : this.handleMondayFormRedirection
            }
          >
            {this.getButtonText()}
          </button>
          {!get(this.state, "navigationStatus.isAccepted") && (
            <span
              className={styles.goBackBtn}
              data-testid="goBackBtn1"
              onClick={() => {
                segment.track.backToSelectConfirmedOffer();
                this.props.history.push(OFFER_SUMMARY);
              }}
            >
              Go Back
            </span>
          )}
        </div>
      );
    }
    if (this.isFormIncomplete()) {
      return (
        <div
          className={`${styles.pdfContractContainer} ${styles.getContractContainer} ${styles.mobileBtnContainer}`}
        >
          <span className={styles.pill}>
            <WarningIcon /> <span>Onboarding Form is Incomplete</span>
          </span>
          <p className={styles.buttonNote}>{ONBOARDING_NOTE}</p>
          <button
            className={`${styles.primary}`}
            onClick={this.proceedToOnboarding}
          >
            Resume Onboarding
          </button>
          {!get(this.state, "navigationStatus.isAccepted") && (
            <span
              className={styles.goBackBtn}
              data-testid="goBackBtn2"
              onClick={() => {
                segment.track.backToSelectConfirmedOffer();
                this.props.history.push(OFFER_SUMMARY);
              }}
            >
              Go Back
            </span>
          )}
        </div>
      );
    }
    return (
      <div
        className={`${styles.pdfContractContainer} ${styles.getContractContainer}`}
      >
        <p className={styles.bold}>Don’t see an email?</p>
        <button
          className={`${styles.secondary}`}
          data-testid="contactBtn"
          onClick={this.handleContactToggle}
        >
          <TextMessage />
          <span>Contact Us</span>
        </button>
      </div>
    );
  };

  successOnboardMsg = () => {
    if (get(this.state, "onboardData.entityType") === ENTITY_OPTIONS[0].value) {
      return this.renderEmailMsg(get(this.state, "onboardData.artistEmail"));
    }
    return (
      <>
        <p className="text-center">
          We have sent <span>{get(this.state, "onboardData.artistName")}</span>{" "}
          an email at <span>{get(this.state, "onboardData.artistEmail")}</span>.
          The email is from no-reply@{getPartnerName()}.com.
        </p>
        <p className="text-center">
          They will need to click on the link in the email to enter our
          {getPartnerName()} portal to securely complete their onboarding by
          providing banking details and completing ID verification.
        </p>
      </>
    );
  };

  renderEmailMsg = (email) => {
    return (
      <>
        <p className="text-center">
          We have sent an email to <span>{email}</span> from no-reply@
          {getPartnerName()}.com.
        </p>
        <p className="text-center">
          Click on the link in that email to enter our {getPartnerName()} portal
          to securely complete your onboarding by providing banking details and
          completing ID verification.
        </p>
      </>
    );
  };

  renderConfirmBankEmailMsg = () => {
    return (
      <div className={styles.textContainer}>
        <p>
          Thank you for the request! We’ve sent a sign in link to{" "}
          <a className={styles.emailLink}
            href={`mailto:${get(this.state, "trolleyRecipientEmail", "")}`}
          >
            {get(this.state, "trolleyRecipientEmail", "")}
          </a>{" "}
          for you to securely update your banking information.
        </p>
        <p>
          If this is not the correct email, please email us at{" "}
          <a className={styles.emailLink} href={`mailto:hello@${getPartnerName()}.com`}>
            hello@{getPartnerName()}.com
          </a>
        </p>
        </div>
    );
  };

  renderOfferData = () => {
    let render = !get(this.state.navigationStatus, "isAccepted");
    if (get(this.state.navigationStatus, "is_refinance")) {
      render = this.state.isBankUpdateEmailSent ? false : true;
    }
    return (
      render && (
        <>
          <div
            className={`${styles.pdfContractContainer} ${styles.beforeAcceptOffer}`}
          >
            <div className={styles.offerRightContainer}>
              <div className={styles.menuBarScroll}>
                <div className={`${styles.otherValues} ${styles.collapseMenu}`}>
                  <ul>
                    <li>
                      <span
                        className={`${styles.countText} ${styles.extraToolTipGap}`}
                      >
                        Deal Type{" "}
                        <span
                          data-testid="WorksTooltip"
                          className={styles.tooltip}
                          onClick={() =>
                            this.renderVideoContainer(OFFER_KEYS[0])
                          }
                        >
                          <ToolTipIcon />
                        </span>
                      </span>
                      <div className={styles.fundingValues}>
                        {get(this.state, "offer.works")}
                      </div>
                    </li>
                    {!get(
                      this.state,
                      "navigationStatus.renderNewConfirmedOffers",
                      false,
                    ) &&
                      !!get(this.state, `offer.term`) && (
                        <li>
                          <span className={styles.countText}>
                            Time To Recoup
                          </span>
                          <div className={styles.fundingValues}>
                            {get(this.state, "offer.term")} Years
                          </div>
                        </li>
                      )}
                    <li>
                      <span
                        className={`${styles.countText} ${styles.extraToolTipGap}`}
                      >
                        Recoupment Rate{" "}
                        <span
                          data-testid="RecoupmentRateTooltip"
                          className={styles.tooltip}
                          onClick={() =>
                            this.renderVideoContainer(OFFER_KEYS[2])
                          }
                        >
                          <ToolTipIcon />
                        </span>
                      </span>
                      <div className={styles.fundingValues}>
                        {get(this.state.offer, "royalty", "0")}%
                      </div>
                    </li>
                    {!!get(this.state, `offer.tail_term`) && (
                      <li>
                        <span className={styles.countText}>
                          Post-Recoup Term Length
                        </span>
                        <div className={styles.fundingValues}>
                          {get(this.state, "offer.tail_term")} Years
                        </div>
                      </li>
                    )}
                    {!!get(this.state, "offer.tail_rev_share") && (
                      <li>
                        <span className={styles.countText}>
                          Income Paid Through After Recoupment
                        </span>
                        <div className={styles.fundingValues}>
                          {get(this.state, "offer.tail_rev_share")}
                        </div>
                      </li>
                    )}
                    {!!get(this.state, `offer.recoup_year_higher`) && (
                      <li>
                        <span className={styles.countText}>
                          Est. Time to Recoup
                        </span>
                        <div className={styles.fundingValues}>
                          {get(this.state, "offer.recoup_year_lower")} -{" "}
                          {get(this.state, `offer.recoup_year_higher`)} Years
                        </div>
                      </li>
                    )}

                    <div className={styles.totalEstimateContainer}>
                      {!!get(this.state, "offer.partner") && (
                        <li>
                          <span
                            className={`${styles.countText} ${styles.extraToolTipGap}`}
                          >
                            Distributor
                            <span
                              data-testid="DistributorTooltip"
                              className={styles.tooltip}
                              onClick={() =>
                                this.renderVideoContainer(OFFER_KEYS[5])
                              }
                            >
                              <ToolTipIcon />
                            </span>
                          </span>
                          <div
                            className={`${styles.fundingValues} ${styles.partnerLogo}`}
                          >
                            {get(this.state.offer, "partner") ===
                            DEFAULT_PARTNER
                              ? "Current Distributor"
                              : get(this.state.offer, "partner")}
                          </div>
                        </li>
                      )}
                      {!!get(
                        this.state,
                        "partnerDetails.distributionFee",
                        0,
                      ) && (
                        <li>
                          <span
                            className={`${styles.countText} ${styles.extraToolTipGap}`}
                          >
                            Distribution Fee
                            <Tooltip
                              place="top"
                              dark
                              id="distributionFee"
                              content={
                                "This is the annual cost charged by the distributor for their services."
                              }
                              delay={200}
                            />
                          </span>
                          <div className={styles.fundingValues}>
                            {get(
                              this.state,
                              "partnerDetails.distributionFee",
                              "0%",
                            )}
                          </div>
                        </li>
                      )}
                      <li>
                        <span className={`${styles.countText} ${styles.bold}`}>
                          Total Advance
                        </span>
                        <div
                          className={`${styles.fundingValues} ${styles.bold}`}
                        >
                          $
                          <Odometer
                            value={parseInt(
                              get(this.state.offer, "total_advance", 0),
                            )}
                            format="(,ddd).dd"
                            duration={400}
                          />
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    );
  };

  getDealSummaryHeader = () => {
    return (
      <>
        <div className={`${styles.titleWithToolTip}`}>
          <p>Deal Summary</p>
        </div>
        <span className={styles.expireDate}>
          Valid through{" "}
          {moment(get(this.state.offer, "expire")).format("MMM DD, YYYY")}
        </span>
      </>
    );
  };

  getActionHeaderContent = () => {
    let content;
    const { isAccepted, isMondayFlow, is_refinance } =
      this.state.navigationStatus;
    switch (true) {
      case this.state.isBankUpdateEmailSent && is_refinance:
        content = this.renderConfirmBankEmailMsg();
        break;
      case !isAccepted && !isMondayFlow:
      case !isAccepted && isMondayFlow:
        content = this.getDealSummaryHeader();
        break;
      case isAccepted && isMondayFlow:
        content = (
          <>
            <p>
              Here’s a summary of your main deal points. Please review it
              carefully - you can download it or email it to yourself.
            </p>
            <p>
              When you are ready, click proceed and we’ll ask you for the
              information we need to generate a contract.
            </p>
          </>
        );
        break;
      case isAccepted && !isMondayFlow && !is_refinance:
        content = this.successOnboardMsg();
        break;
      default:
        content = this.getDealSummaryHeader();
        break;
    }
    return content;
  };

  renderRightNav = () => (
    <div className={styles.actionContainer}>
      <div className={styles.btnOnboarding}>
        <div className={styles.actionsHeaderContainer}>
          {this.getActionHeaderContent()}
        </div>
        {this.renderOfferData()}
        {this.actionButtonContainer()}
      </div>
    </div>
  );

  warningDiv = () => (
    <div className={styles.mainWarningDiv}>
      <span>
        {this.state.agentCode
          ? `${getPartnerName()} is a direct to artists platform. Estimates and offers
        presented outside of our app by unauthorized representatives will not be
        honored.`
          : `beatBread is a direct to artists platform. Estimates and offers
          presented outside of our app by unauthorized representatives may not be
          honored and be invalidated by ${getPartnerName()}.`}
      </span>
    </div>
  );

  render() {
    const headerTitle = "Review Offer Letter";
    return (
      <div className={classes.layoutContainer}>
        <HorizontalMenu
          offerStage={this.state.offerStage}
          className={styles.menuContainer}
          {...this.props}
          getStatus={this.setNavigationStatus}
          updateUserData={this.updateUserData}
        />
        <div className={classes.funnelContainer}>
          <LoginHeader headerTitle={headerTitle} titleClass />
          <div className={styles.pageContainer}>
            <div className={styles.mainContainer}>
              <div className={styles.scrollContainer}>
                <div
                  className={`${styles.container} ${
                    !get(this.state, "navigationStatus.isMondayFlow") &&
                    (get(this.state, "onboardData.representativeEmail") ||
                      get(this.state, "onboardData.artistName"))
                      ? styles.onboardFlow
                      : ""
                  }`}
                >
                  <div className={styles.mainContentContainer}>
                    <div className={styles.offerLetterContainer}>
                      <div className={styles.title}>
                        <span className={styles.titleHeader}>Offer letter</span>
                        <span className={styles.expireDate}>
                          Valid through{" "}
                          {moment(get(this.state.offer, "expire")).format(
                            "MMM DD, YYYY",
                          )}
                        </span>
                        <span className={styles.actionText}>
                          {this.state.navigationStatus.isOnBoardFormSubmit
                            ? POST_OFFER_ACTION_TEXT
                            : OFFER_ACTION_TEXT}
                        </span>
                      </div>
                      <div
                        className={`${styles.detailsContainer} ${
                          this.state.loading ? "d-none" : ""
                        }`}
                      >
                        {this.state.pdfLoading && (
                          <div className={styles.pdfContainer}>
                            {this.pdfLoading()}
                          </div>
                        )}
                        {this.state.pdfUrl && (
                          <div className={styles.pdfContainer}>
                            <Document
                              file={this.state.pdfUrl}
                              onLoadSuccess={this.onDocumentLoadSuccess}
                              onLoadError={this.onPDFLoadError}
                              loading={this.renderPDFLoad}
                            >
                              {Array.from(
                                new Array(this.state.totalPDFPage),
                                (el, index) => (
                                  <Page
                                    width={get(
                                      document,
                                      "body.clientWidth",
                                      900,
                                    )}
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                  />
                                ),
                              )}
                            </Document>
                          </div>
                        )}
                        <div className={styles.offerDownload}>
                          <div className={styles.offerFooter}>
                            {!get(
                              this.state,
                              "navigationStatus.isAccepted",
                            ) && (
                              <span
                                className={styles.goBackBtn}
                                data-testid="goBackBtn"
                                onClick={() => {
                                  segment.track.backToSelectConfirmedOffer();
                                  this.props.history.push(OFFER_SUMMARY);
                                }}
                              >
                                Go Back
                              </span>
                            )}
                            <div
                              className={styles.btnLink}
                              onClick={this.downloadOffer}
                              disabled={
                                this.state.loading || !this.state.pdfUrl
                              }
                            >
                              <ASSETS.DownloadLogo />
                            </div>
                            <span
                              onClick={this.downloadOffer}
                              className={`${styles.secondaryOffer}`}
                              disabled={
                                this.state.loading || !this.state.pdfUrl
                              }
                              data-testid="downloadPdf"
                            >
                              Download Offer Letter
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.rightContainer}>
                      {this.renderRightNav()}
                    </div>
                  </div>

                  <div className={styles.mainFooterContainer}>
                    <div className={styles.footerTooltipContainer}>
                      <ToolTipContent />
                      <p className={styles.toolTipContent}>
                        {this.warningDiv()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(this.state.loading || this.state.submitLoader) && (
            <Loader light backgroundNone />
          )}
          {this.renderModal()}
          {this.renderBankUpdateEmailSentModal()}
          {this.onboardFormModal()}
          <ContactPopup
            isOpen={this.state.isContactOpen}
            onRequestClose={this.handleContactToggle}
          />
        </div>
      </div>
    );
  }
}

AcceptOffer.contextType = ThemeContext;
export default AcceptOffer;
