import { useEffect, useState } from "react";
import { LOGGED_OUT, LOGIN } from "../routes/pages/constants";
import AuthTokenService from "./AuthTokenService";
import StorageService from "./StorageService";
import { datadogRum } from "@datadog/browser-rum";
import { getPartnerName } from "../component/ThemeManager/helper";
import { invoke } from "lodash";
import Hotjar from "@hotjar/browser";

export function userExists() {
  if (AuthTokenService.exists()) {
    AuthTokenService.init();
    return true;
  }
  return false;
}

export function logoutFromWaitingRoom(fromWaitingRoom) {
  StorageService.delete("role");
  StorageService.delete("isAdminInMaintenance");
  AuthTokenService.clear();

  if (fromWaitingRoom) {
    this.props.history.push({
      pathname: LOGGED_OUT,
      state: {
        detail: this.state.selectedArtist
          ? this.state.selectedArtist
          : this.state.selectedArtistStatus,
      },
    });
    return;
  }
  window.location.reload();
}

export function logout() {
  window.location.reload();
  StorageService.delete("role");
  StorageService.delete("isAdminInMaintenance");
  AuthTokenService.clear();
}

export function autoLogout() {
  StorageService.delete("role");
  StorageService.delete("isAdminInMaintenance");
  AuthTokenService.clear();

  this.props.history.push({
    pathname: LOGIN,
    state: {
      autoLogin: true,
    },
  });
}

export function initDataDog() {
  const isCall = process.env.REACT_APP_DATA_DOG_CALL;
  if (isCall) {
    datadogRum.init({
      clientToken: process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN,
      applicationId: process.env.REACT_APP_DATA_DOG_APPLICATION_ID,
      site: process.env.REACT_APP_DATA_DOG_SITE,
      service: process.env.REACT_APP_DATA_DOG_SERVICE,

      // Specify a version number to identify the deployed version of your application in Datadog
      version: process.env.REACT_APP_BUILD_VERSION,
      sampleRate: 100,
      sessionReplaySampleRate: 0,
      trackInteractions: true,
      defaultPrivacyLevel: "mask-user-input",
    });
    datadogRum.startSessionReplayRecording();
  }
}

export function setDataDogUser(id, name, email) {
  if (process.env.REACT_APP_DATA_DOG_CALL) {
    datadogRum.setUser({
      id,
      name,
      email,
    });
  }
}

export const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);
  useEffect(() => {
    const mediaQuery = window.matchMedia(query);
    const handleMediaQueryChange = (e) => {
      setMatches(e.matches);
    };
    setMatches(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, [query]);
  return matches;
};

export const initSparrowSurvey = (params = {}, isRejected) => {
  let surveyToken,
    surveyName = "";
  if (isRejected) {
    surveyToken =
      getPartnerName() === "beatBread"
        ? process.env.REACT_APP_SPARROW_SURVEY_REJECTED_BB
        : process.env.REACT_APP_SPARROW_SURVEY_REJECTED_CC;
    surveyName =
      getPartnerName() === "beatBread"
        ? process.env.REACT_APP_SURVEY_NAME_REJECTED_BB
        : process.env.REACT_APP_SURVEY_NAME_REJECTED_CC;
  } else {
    surveyToken =
      getPartnerName() === "beatBread"
        ? process.env.REACT_APP_SPARROW_SURVEY_BB
        : process.env.REACT_APP_SPARROW_SURVEY_CC;
    surveyName =
      getPartnerName() === "beatBread"
        ? process.env.REACT_APP_SURVEY_NAME_BB
        : process.env.REACT_APP_SURVEY_NAME_CC;
  }
  if (surveyToken) {
    const s1 = document.createElement("script");
    s1.id = "SS_SCRIPT";
    s1.innerHTML = `function sparrowLaunch(opts){var e="ss-widget",t="script",a=document,r=window,l=localStorage;var s,n,c,rm=a.getElementById('SS_SCRIPT');r.SS_WIDGET_TOKEN="${surveyToken}";r.SS_ACCOUNT="beatbread.surveysparrow.com";r.SS_SURVEY_NAME="${surveyName}";if(!a.getElementById(e) && !l.getItem('removed-ss-widget-${surveyToken}')){var S=function(){S.update(arguments)};S.args=[];S.update=function(e){S.args.push(e)};r.SparrowLauncher=S;s=a.getElementsByTagName(t);c=s[s.length-1];n=a.createElement(t);n.type="text/javascript";n.async=!0;n.id=e;n.src=["https://","beatbread.surveysparrow.com/widget/",r.SS_WIDGET_TOKEN,"?","customParams=",JSON.stringify(opts)].join("");c.parentNode.insertBefore(n,c);r.SS_VARIABLES=opts;rm.parentNode.removeChild(rm);}};`;
    s1.async = true;
    const s2 = document.createElement("script");
    s2.innerHTML = `sparrowLaunch(${JSON.stringify(params)})`;
    s2.async = true;
    const surveyDiv = document.getElementById("ss_survey_widget");
    invoke(surveyDiv, "appendChild", s1);
    invoke(surveyDiv, "appendChild", s2);
  }
};

export const initiateHotjar = () => {
  if (process.env.REACT_APP_HOTJAR_ID) {
    const hotjarVersion = 6;
    Hotjar.init(process.env.REACT_APP_HOTJAR_ID, hotjarVersion);
  }
};
